<template>
  <div class="card gutter-b card-stretch card-custom">
    <div class="card-header h-auto border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="d-block text-dark font-weight-bolder"
            >{{ $t("TRANSLATOR.STATISTICS", { msg: "STATISTICS" }) }}</span
          >
          <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{ $t("TRANSLATOR.FROM") }} : {{ this.dates[0] }} {{ $t("TRANSLATOR.TO") }} : {{ this.dates[1] }}</span>
        </h3>
      <div class="card-toolbar flex">
        <b-dropdown
        size="sm"
        variant="link"
        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        no-caret
        right
        no-flip
        class="mr-2 mt-2"
      >
        <template v-slot:button-content>
          <slot><i class="ki ki-calendar-2"></i></slot>
        </template>
        <!--begin::Navigation-->
        <div class="navi navi-hover min-w-md-250px">              
          <b-dropdown-text tag="div" class="navi-header font-weight-bold">
            <!-- <span class="font-size-lg">
              Choose your date:
            </span> -->
        <v-date-picker
          v-model="dates"
          width="290"
          range
          color="primary"
          scrollable
          no-title
          @change="getData()"
        ></v-date-picker>
          <!-- no-title -->
        <!-- <v-text-field
          v-model="dateRangeText"
          label="Date range"
          prepend-icon="mdi-calendar"
          readonly
        ></v-text-field> -->
          </b-dropdown-text>
        </div>
      </b-dropdown>
        <a class="btn btn-sm btn-primary font-weight-bolder font-size-sm mr-2 mt-2" @click="getLastWeek()">
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg src="media/svg/icons/Code/Right-circle.svg" />
          </span>{{ $t("TRANSLATOR.LASTWEEK", { msg: "LASTWEEK" }) }}</a>
          <a class="btn btn-sm btn-primary font-weight-bolder font-size-sm mr-2 mt-2" @click="getThisMonth()">
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg src="media/svg/icons/Code/Right-circle.svg" />
          </span>{{ $t("TRANSLATOR.THISMONTH", { msg: "THISMONTH" }) }}</a>
          <a class="btn btn-sm btn-primary font-weight-bolder font-size-sm mt-2" @click="getLastMonth()">
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg src="media/svg/icons/Code/Right-circle.svg" />
          </span>{{ $t("TRANSLATOR.LASTMONTH", { msg: "LASTMONTH" }) }}</a>
      </div>
    </div>
  <!-- <div class="card-header h-auto py-5 border-0">
  </div> -->
    <!--begin::Content-->
    <div class="table-responsive">
        <div class="text-center ml-5 mr-5">
          <b-nav tabs>
            <b-nav-item @click="filterData(0)" :active="this.AllActive">All</b-nav-item>
            <b-nav-item @click="filterData(1)" :active="this.PrecoActive">Preco</b-nav-item>
            <b-nav-item @click="filterData(2)" :active="this.FranceActive">France</b-nav-item>
            <b-nav-item @click="filterData(3)" :active="this.EquateurActive">Equateur</b-nav-item>
            <b-nav-item @click="filterData(4)" :active="this.HollandActive">Holland</b-nav-item>
            <b-nav-item @click="filterData(5)" :active="this.SecActive">Sec</b-nav-item>
          </b-nav>
        <!-- </div> -->
        </div>
      <vue-good-table
        :columns="columns"
        :rows="data"
        styleClass="vgt-table striped"
        :fixed-header="false"
        compactMode
        :pagination-options="{
          enabled: false,
          perPage: 50,
          mode: 'pages'
        }"  
        :search-options="{
          enabled: false,
          placeholder: 'Search for a customer',
        }">
  <template slot="table-row" slot-scope="props">
    <span v-if="props.column.field == 'margePerc'">
      <!-- <span v-if="props.row.margePerc > 8 && props.row.type == 'AP Clients'" class="text-success font-weight-bold"> {{ formatNumbers(props.row.margePerc) }}%</span>
      <span v-if="props.row.margePerc == ''" class="svg-icon">-</span>
      <span v-if="props.row.margePerc < 8 && props.row.type == 'AP Clients'" class="text-danger font-weight-bold">{{ formatNumbers(props.row.margePerc) }}%</span> -->
      <!-- <span v-if="props.row.type !== 'AP Clients'" class="font-weight-bold">{{ formatNumbers(props.row.margePerc) }}%</span> -->
      <span class="font-weight-bold">{{ formatNumbers(props.row.margePerc) }}%</span>
    </span>
    <span v-else-if="props.column.field == 'verkoopPerc'">
      <span class="font-weight-bold">{{ formatNumbers(props.row.verkoopPerc) }}%</span>
    </span>
    <span v-else>
      {{props.formattedRow[props.column.field]}}
    </span>
  </template>
      </vue-good-table>
    </div>
  </div>
    <!--end::Content-->
</template>
<script>

import JwtService from "@/core/services/jwt.service";
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import router from "../../router";
export default {
  name: "custom-page",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      companyId: JwtService.authorizeToken().companyId,
      loading: true,
      data:[],
      error: [],
      columns: [
        {
        label: 'Type',
        field: 'type',
        thClass: 'text-muted font-size-sm',
        tdClass: ' font-size-lg',
        width: '14%',
        sortable: false,
        // width: '100px',
        // filterOptions: {
        // styleClass: 'class1', // class to be added to the parent th element
          // enabled: true, // enable filter for this column
          // placeholder: 'Filter', // placeholder for filter input
          // filterValue: 'Jane', // initial populated value for this filter
          // filterDropdownItems: [], // dropdown (with selected values) instead of text input
          // filterFn: this.columnFilterFn, //custom filter function that
          // trigger: 'enter', //only trigger on enter not on keyup 
        //},
        },
        {
          label: 'Total',
          field: 'totalTige',
          width: '14%',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          sortable: false,
          formatFn: this.formatNumbers,
          // width: '100px',
        },
        {
          label: 'Achat',
          field: 'inkoop',
          width: '14%',
          sortable: false,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
          formatFn: this.formatNumbers,
          // type: 'date',
          // dateInputFormat: 'yyyy-MM-dd',
          // dateOutputFormat: 'MMM do yy',
        },
        {
          label: 'Vente',
          field: 'verkoop',
          sortable: false,
          width: '14%',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-dark-75 font-weight-bolder font-size-lg',
          tooltip: 'A simple tooltip',
          formatFn: this.formatNumbers,
        },
        {
          label: 'Marge',
          field: 'marge',
          sortable: false,
          width: '14%',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-dark-75 font-weight-bolder font-size-lg',
          formatFn: this.formatNumbers,
        },
        {
          label: 'Margin %',
          field: 'margePerc',
          sortable: false,
          width: '14%',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
        },
        {
          label: 'Vente %',
          field: 'verkoopPerc',
          width: '14%',
          sortable: false,
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-muted font-size-sm',
        },
      ],
      // lineId: this.$route.params.id,
      startdate: null,
      enddate: null,
      dates: [this.startdate, this.enddate],
      AllActive: true,
      PrecoActive: false,
      FranceActive: false,
      EquateurActive: false,
      HollandActive: false,
      SecActive : false,
      VoorCod: "'V1','V5','V6','V7','V9','V10'",
    }
  },
  mounted() {
    this.checkAutorization();
    this.getThisWeek();
    this.filterData(0);
    this.getData();
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    dateIsValide(){
      var DateStart = new Date(this.dates[0]);
      var DateEnd = new Date(this.dates[1]);

      if(DateStart > DateEnd){
        var temp = this.dates[0];
        this.dates[0] = this.dates[1];
        this.dates[1] = temp;
      }
      },
      getData(){
        this.dateIsValide();
        axios
        .get('/home/getStatistique', {
          params: {
            startdate: this.dates[0],
            enddate: this.dates[1],
            VoorCod: this.VoorCod,
          }
        })
        .then(response => {
          this.data = response.data;
        })
    },
    getThisWeek(){
      var today = new Date();
      if(today.getDay() == 0){
        today.setDate(today.getDate() - 1);
      }
      var firstday = new Date(today.setDate(today.getDate() - today.getDay()+1));  //First day is the day of the month - the day of the week
      var lastday = new Date(today.setDate(today.getDate() - today.getDay()+7)); 
      firstday = this.getDateYYYYMMDD(firstday);
      lastday = this.getDateYYYYMMDD(lastday);
      this.dates = [firstday, lastday];
    },
    getLastWeek(){
      var today = new Date();
      //var today = new Date("August 6, 2023");
      var firstday = new Date(today.setDate(today.getDate() - today.getDay()+1));  //First day is the day of the month - the day of the week
      var lastday = new Date(today.setDate(today.getDate() - today.getDay()+7)); 
      firstday = new Date(firstday.setDate(firstday.getDate() - 7));  // -7 for last week
      lastday = new Date(lastday.setDate(lastday.getDate() - 7));
      firstday = firstday.toISOString().substr(0, 10); //YYYY-MM-DD
      lastday = lastday.toISOString().substr(0, 10);
      this.dates = [firstday, lastday];
      this.getData();
    },
    getThisMonth(){
      var startdate = (new Date(new Date().getFullYear(), new Date().getMonth(), 2)).toISOString().substr(0, 10);
      var enddate = (new Date(Date.now())).toISOString().substr(0, 10);
      this.dates = [startdate, enddate];
      this.getData();
    },
    getLastMonth(){
      var startdate = (new Date(new Date().getFullYear(), new Date().getMonth()-1, 2)).toISOString().substr(0, 10);
      var enddate = (new Date(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString().substr(0, 10);
      this.dates = [startdate, enddate];
      this.getData();
    },
    getDateYYYYMMDD(date) {
      var d = new Date(date),
        month = (d.getMonth() + 1),
        day =  d.getDate(),
        year = d.getFullYear();
      return [year, month, day].join("-"); 
    },
    filterData(filterNumber){
    if(filterNumber == 0){
      this.VoorCod = "V1,V5,V6,V7,V9,V10";
      this.AllActive = true;
      this.PrecoActive = false;
      this.FranceActive = false;
      this.EquateurActive = false;
      this.HollandActive = false;
      this.SecActive = false;
    }
      if(filterNumber == 1){
        this.VoorCod = "V6";
        this.AllActive = false;
        this.PrecoActive = true;
        this.FranceActive = false;
        this.EquateurActive = false;
        this.HollandActive = false;
        this.SecActive = false;
      }
      if(filterNumber == 2){
        this.VoorCod = "V9";
        this.AllActive = false;
        this.PrecoActive = false;
        this.FranceActive = true;
        this.EquateurActive = false;
        this.HollandActive = false;
        this.SecActive = false;
      }
      if(filterNumber == 3){
        this.VoorCod = "V10";
        this.AllActive = false;
        this.PrecoActive = false;
        this.FranceActive = false;
        this.EquateurActive = true;
        this.HollandActive = false;
        this.SecActive = false;
      }
      if(filterNumber == 4){
        this.VoorCod = "V1";
        this.AllActive = false;
        this.PrecoActive = false;
        this.FranceActive = false;
        this.EquateurActive = false;
        this.HollandActive = true;
        this.SecActive = false;
      }
      if(filterNumber == 5){
        this.VoorCod = "V5,V7";
        this.AllActive = false;
        this.PrecoActive = false;
        this.FranceActive = false;
        this.EquateurActive = false;
        this.HollandActive = false;
        this.SecActive = true;
      }
      this.getData();
    },
    checkAutorization(){
      if(this.companyId!=1 && this.companyId != 3){   
      router.push({ name: '404' });
      }
    },
    formatNumbers: function(value) {
      return value.toLocaleString('nl-NL');
    },
  },
  computed: {
  }
};
</script>
<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }
  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
.navi .navi-header {
    padding: 0;
}

.b-dropdown-text {
    padding: 0;
}
.v-picker__title {
  background-color: #2196f3 !important;
  color: white;
}
.v-date-picker-table .v-btn.v-btn--active {
  background-color: #c7c7c7 !important;
}
</style>
